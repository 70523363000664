<template>
  <span
    @click.stop.prevent="onClick"
    :class="busy ? 'busy' : isValid && isToggleEnabled && !disabled ? 'enabled' : 'disabled'"
    :title="title"
  >
    <i v-if="busy" class="fa fa-refresh fa-spin"></i>
    <i v-else :class="`fa fa-toggle-${isToggleEnabled ? 'on' : 'off'} `"></i>
    <span v-if="!busy">
      {{ $t(isToggleEnabled ? "yes" : "no") }}
    </span>
  </span>
</template>

<script>
// default field configuration
const field = () => {
  return {
    name: "toggle",
    title: "enabled",
    parser: (item) => {
      return `${String.fromCharCode(
        122 + parseInt(item.enabled ? 0 : 1)
      )} ${this.$t(item.enabled ? "yes" : "no")} ${
        item.enabled ? "true" : "false"
      }`;
    },
    style() {
      return {"text-align": "center"};
    }
  };
};
export {field};
export default {
  name: "ResourceToggle",
  props: {
    item: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    busy: false,
    warned: false
  }),
  computed: {
    isToggleEnabled() {
      return this.item.enabled;
    },
    title() {
      return this.busy
        ? this.$t("please_wait")
        : this.$tc("synoptic.events.change", 2);
    },
    isValid() {
      return (
        this.item && this.item.id && "enabled" in this.item && this.modelAPI
      );
    }
  },
  methods: {
    onClick() {
      if (this.busy || this.disabled || !this.isValid) return;
      if (!this.isToggleEnabled || this.warned) {
        this.save();
      } else {
        this.confirm(
          this.$t("are_you_sure"),
          this.$t("hints.disabling_resource")
        ).then(() => {
          this.warned = true;
          this.save();
        });
      }
    },
    async save() {
      const model = this.modelAPI();
      const _save = async () => {
        const item = await model.service.get(this.item.id);
        if (!item.etag) {
          this.busy = false;
          return;
        }
        model.service
          .save({
            id: item.id,
            etag: item.etag,
            enabled: !this.item.enabled
          })
          .then((resp) => {
            this.busy = false;
            if (Array.isArray(resp) && resp.length) {
              resp = resp[0];
            }
            if (model.validator) {
              if (model.validator(resp)) {
                this.item.enabled = resp.enabled;
                this.item.etag = resp.etag;
                this.$store.dispatch("dashboard/updateAfterSave", {
                  type: model.type,
                  resource: resp
                });
                this.$emit("update", this.item);
              }
              model.alert();
            } else {
              if (resp.etag) {
                this.item.enabled = resp.enabled;
                this.item.etag = resp.etag;
                this.$store.dispatch("dashboard/updateAfterSave", {
                  type: model.type,
                  resource: resp
                });
                this.$emit("update", this.item);
              }
            }
          });
      };
      if (this.item.etag) {
        this.busy = true;
        _save();
      } else {
        this.busy = true;
        model.service.get(this.item.id).then((resp) => {
          if (resp && resp.etag) {
            this.item.etag = resp.etag;
            _save();
          } else {
            this.busy = false;
            // TODO: show error message;
          }
        });
      }
    },
    modelAPI() {
      const parent = this.parent || this?.$parent?.$parent || null;
      return this.item && parent
        ? {
            type: parent.$data.entity,
            service: parent.service,
            validator: parent.validateSaveResponse,
            alert: parent.showAlert,
            commiter: {
              alarm: "dashboard/SET_ALARM_VALUE",
              data: "dashboard/SET_DATA_VALUE",
              device: "dashboard/SET_DEVICE_VALUE",
              connector: "dashboard/SET_CONNECTOR_VALUE"
            }[parent.$data.entity]
          }
        : null;
    },
    confirm(title, msg) {
      return new Promise((resolve) => {
        let wrapper = document.createElement("div");
        wrapper.innerHTML = `${msg}<br><span class="text-warning"><i class='fa fa-exclamation-triangle'></i> ${this.$t(
          "hints.disabled_resources"
        )}</span>`;
        this.$swal({
          title: title,
          content: wrapper,
          buttons: [this.$t("cancel"), this.$t("confirm")]
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(true);
            return;
          }
          resolve(false);
        });
      });
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.busy:hover {
  cursor: wait;
  color: gray;
  opacity: 0.8;
}

.enabled {
  color: #31708f;
}
.disabled {
  color: gray;
}
</style>
