import Vue from "vue";
import DataCurrentValueService from "@/services/data-current-value";
import {debounce, isEqual} from "lodash";
const Component = {
  name: "WriteCommandFormManager",
  data: () => ({
    busy: false
  }),
  computed: {
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    dirtyForms() {
      return this.getInputControls().reduce((a, i) => {
        let formName =
          i?.control?.formName || i?.control?.synopticComponent?.formName || "";
        a[formName] =
          (a[formName] ?? 0) +
          ((typeof i.isDirty == "function"
          ? i.isDirty(i)
          : !i.hasFocus && i.isDirty)
            ? 1
            : 0);
        return a;
      }, {});
    }
  },
  watch: {
    dirtyForms(n, o) {
      if (isEqual(n, o)) return;
      this.$store.dispatch("dashboard/setDirtyForms", n);
    }
  },
  methods: {
    restore(lst) {
      let ctrlList = this.getInputControls();
      (ctrlList || []).forEach((i) => {
        if (
          i.isDirty &&
          (i?.control?.formName || i?.i?.synopticComponent?.formName)
        ) {
          let data = lst.find(({data_id}) => data_id == i.data_id);
          if (data) {
            i.value = data.value;
            i.manuallyChanged = false;
            i.formSaving = false;
          }
        }
      });
    },
    sync(entry) {
      this._sync =
        this._sync ||
        debounce((lst) => {
          // if (!this._pending_list || !this._pending_list.length) return;
          if (!lst.length) return;
          // console.log(`< ${new Date().getTime()} ${lst}`);
          this.$store
            .dispatch("dashboard/fetchDataState", {
              ids: lst
            })
            .then((resp) => {
              let readyIdList = (resp || [])
                .filter(
                  ({pending_commands}) =>
                    !((pending_commands || []).length || 0)
                )
                .map(({id}) => parseInt(id));
              if (!readyIdList.length) return;
              this.$store.dispatch(
                "dashboard/fetchDataAlarmsState",
                readyIdList
              );
              let query = {
                contract_id: this.$store.getters["user/contract_id"],
                data_ids: readyIdList.join(",")
              };
              this.$store
                .dispatch("dashboard/fetchDataSamples", query)
                .then((r) => {
                  let pendingList = (this._pending_list || []).filter(
                    (a) => !lst.some((b) => parseInt(b) == parseInt(a))
                  );
                  this._pending_list = pendingList.length ? pendingList : null;
                  this.restore(r);
                });
            });
        }, 200);
      this._sync(
        ((entry && entry.data_ids) || []).length
          ? entry.data_ids
          : [...(this._pending_list || [])]
      );
    },
    getCommandTypeId() {
      return this.$root.config.references.command_types.data_writing;
    },
    getInputControls() {
      if (typeof this.$parent.getInputControls == "function") {
        return (this.$parent.getInputControls() || []).filter((i) => i.control);
      }
      return [];
    },
    getDirtyControls(formName) {
      return this.getInputControls().filter(
        (i) =>
          i.isDirty &&
          (!formName ||
            formName ==
              (i?.control?.formName ||
                i?.control?.synopticComponent?.formName ||
                ""))
      );
    },
    onSuccess(msg) {
      this.$toasted.show(msg, {
        position: "bottom-right",
        duration: 5000,
        keepOnHover: true,
        type: "success",
        icon: "check",
        iconPack: "fontawesome"
      });
    },
    onError(errorList) {
      return this.$swal({
        title: this.$t("not_allowed"),
        text: errorList.join("\n").replace(/(detail:)/i, ""),
        icon: "error",
        type: "error"
      });
    },
    submit(form) {
      if (this.busy) return;
      let ctrlList =
        Array.isArray(form) && form.length ? form : this.getDirtyControls(form);
      if (ctrlList.length) {
        let ix, lst, data, valueAsArrayList, indexAsArrayList;
        // Since more than one input control might be pointing to the same data
        // a single current_value should be handled for the array update purposes
        ctrlList.forEach((control) => {
          ix = control.dataValueCurrentIndex ?? -1;
          if (ix >= 0) {
            valueAsArrayList = valueAsArrayList || {};
            lst = valueAsArrayList[control.lastData.id];
            if (!lst) {
              data = this.dataList.find(
                ({id}) => parseInt(id) == parseInt(control.lastData.id)
              );
              if (data) {
                lst = [...new Array(data?.memory_size || 1)].map((i, ix) =>
                  this.$root.$formatter.rawValue(data, ix)
                );
                valueAsArrayList[control.lastData.id] = lst;
              }
            }
            if (lst && lst?.length && ix >= 0 && ix < lst.length) {
              lst[ix] =
                control.lastData.type == "string"
                  ? this.$utils
                      .removeDiacritics(control.iValue)
                      .replace(/[,]/g, "")
                  : Number(control.iValue);
              // while index are linked to another data, such item must be included too
              if (control.dataValueIndex && control.dataValueIndex.data_id) {
                data = this.dataList.find(
                  ({id}) =>
                    parseInt(id) == parseInt(control.dataValueIndex.data_id)
                );
                if (data) {
                  indexAsArrayList = indexAsArrayList || {};
                  indexAsArrayList[data.id] = Number(
                    this.$root.$formatter.rawValue(data)
                  );
                }
              }
            }
          }
        });

        // process the simple ones
        let payload = {
          command_type_id: this.getCommandTypeId(),
          parameters: ctrlList
            .filter(
              ({lastData}) =>
                !valueAsArrayList || !valueAsArrayList[lastData.id]
            )
            .map((control) => ({
              data_configuration_id: parseInt(control?.lastData?.id),
              writing_value:
                control.lastData.type == "string"
                  ? this.$utils
                      .removeDiacritics(control.iValue)
                      .replace(/[,]/g, "")
                  : Number(control.iValue)
            }))
        };

        // process the array based ones
        if (valueAsArrayList) {
          Object.keys(valueAsArrayList).forEach((dataId) => {
            payload.parameters.push({
              data_configuration_id: parseInt(dataId),
              writing_value: `[${valueAsArrayList[dataId].join(",")}]`
            });
          });
          if (indexAsArrayList) {
            Object.keys(indexAsArrayList).forEach((dataId) => {
              payload.parameters.push({
                data_configuration_id: parseInt(dataId),
                writing_value: indexAsArrayList[dataId]
              });
            });
          }
        }

        // local are not persisted by command, therefore it must relocated to another payload
        let payload_local_data_values = {data: []};
        payload.parameters = payload?.parameters?.filter((item) => {
          let data = this?.dataList?.find(
            ({id}) => parseInt(id) == parseInt(item.data_configuration_id)
          );
          if (data && !data?.is_local) return true;
          payload_local_data_values.data.push({
            data_id: item.data_configuration_id,
            value: item.writing_value
          });
          return false;
        });

        let promises = [];
        let commandPayload = -1;
        let apiPayload = -1;

        if (payload && payload?.parameters?.length) {
          promises.push(this.$store.dispatch("command/save", [payload]));
          commandPayload = 0;
        }

        if (
          payload_local_data_values &&
          payload_local_data_values?.data?.length
        ) {
          promises.push(
            this?.dataCurrentValueService?.save(payload_local_data_values)
          );
          apiPayload = commandPayload + 1;
        }

        // Executa apenas se tiver algum comando
        if (!promises.length) return;

        this.busy = true;
        try {
          this.getDirtyControls(form).forEach((i) => {
            i.formSaving = true;
          });
          Promise.all(promises)
            .then((result) => {
              this.busy = false;
              let errorList = [];

              // Tratar Comandos
              if (commandPayload >= 0) {
                (result[commandPayload] || []).forEach((item) => {
                  if (item?.status == "fulfilled" && item?.value?.length) {
                    let idList = this._pending_list || [];
                    idList = this.$utils.distinct(
                      idList.concat(item.value.map(({data_id}) => data_id))
                    );
                    if (this.posponeSync(idList)) {
                      this.$store.dispatch("dashboard/fetchDataState", {
                        ids: idList
                      });
                    } else {
                      // console.log(`> ${new Date().getTime()} ${idList}`);
                      this._pending_list = idList;
                      if (this?.$root?.config?.mqtt?.modbus_enabled)
                        this.$store.dispatch("dashboard/fetchDataState", {
                          ids: idList
                        });
                      else this.sync(); // pooling version of the dashboard - requires initial update;
                    }
                  } else if (item?.status == "rejected" && item.reason) {
                    errorList.push(item.reason);
                  }
                });
              }

              // Tratar Dados Locais
              if (apiPayload >= 0) {
                let data_current_value_list = result[apiPayload] || [];
                this.$store.dispatch(
                  "dashboard/setDataValue",
                  data_current_value_list.map((item) => {
                    item.restore = {
                      value: item.value,
                      datetime: item.datetime
                    };
                    return item;
                  })
                );
              }

              if (!errorList.length) {
                if (apiPayload >= 0) {
                  this.onSuccess(this.$t("data_value_updated"));
                } else {
                  this.onSuccess(this.$t("command_sent"));
                }
              } else {
                this.onError(errorList);
              }
            })
            .catch((error) => {
              this.busy = false;
              this.onError([error]);
              console.log(error); // TODO: implement it
            });
        } catch (error) {
          this.busy = false;
          this.onError([error]);
        }
      }
    },
    reset(formName) {
      if (this.busy) return;
      let lst = this.getDirtyControls(formName);
      if (lst.length) {
        this.busy = true;
        lst.forEach((item) => {
          if (item.restore && typeof item.restore == "function") {
            item.restore();
          }
        });
        this.busy = false;
      }
    },
    disableSaveFromInputControls() {
      const _ctrlSave = () =>
        new Promise((resolve) => {
          resolve();
        });

      const _apply = () => {
        this.$nextTick(() => {
          // disable save for the ones with configured form ;
          this.getInputControls().forEach((i) => {
            if (
              i.save &&
              typeof i.save == "function" &&
              (i?.control?.formName || i?.control?.synopticComponent?.formName)
            ) {
              i.save = _ctrlSave;
              i.hasActiveForm = true;
            }
          });
        });
      };
      let _tm = 1000;
      let _loop = setInterval(
        () => {
          _tm -= 100;
          if (this.$parent._isMounted || _tm < 0) {
            clearInterval(_loop);
            _loop = null;
            _apply();
          }
        },
        100,
        this
      );
    },
    listen(o, formEvents) {
      if (formEvents) {
        this.$root[`$${o}`]("form:submit", this.submit);
        this.$root[`$${o}`]("form:reset", this.reset);
      }
      this.$root[`$${o}`]("data:sync", this.sync);
    },
    posponeSync(lst) {
      if (!lst || !lst.length) return true;
      let data = null;
      let mqttDataList = lst.filter((data_id) => {
        data = this.dataList.find(({id}) => parseInt(data_id) == parseInt(id));
        return (
          (data && data?.device?.connector?.protocol?.is_mqtt_protocol) ?? false
        );
      });
      return mqttDataList.length == lst.length;
    }
  },
  render() {
    return [];
  },
  beforeCreate() {
    if (
      !this?.$parent?.getInputControls ||
      typeof this.$parent.getInputControls !== "function"
    ) {
      console.log(
        "Missing a valid getInputControls function in the parent component"
      );
    }
  },
  created() {
    this.dataCurrentValueService = new DataCurrentValueService();
    this.disableSaveFromInputControls();
  }
};

export default class CommandFormManager {
  constructor(parent, formEvents) {
    this._fe = formEvents ?? true;
    this._vm = new (Vue.extend(Component))({parent: parent});
    this._vm.listen("on", this._fe);
  }

  destroy() {
    this._vm.listen("off", this._fe);
    this._vm.$destroy();
    this._vm = null;
    return null;
  }

  submit(formName) {
    this._vm.submit(formName);
  }

  reset(formName) {
    this._vm.reset(formName);
  }
}
