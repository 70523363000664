<template>
  <section>
    <div class="form-group">
      <div class="form-control text-center btn-container">
        <span
          class="btn btn-sm btn-info-hi"
          :disabled="!connector.id"
          @click.stop.prevent="
            connector.id ? (showConfig = !showConfig) : undefined
          "
        >
          <i class="fa fa-gears"></i>
          {{ $tc("titles.connection_instructions", 1) }}
        </span>
        <span
          v-if="!connector.base_model"
          class="btn btn-sm btn-white"
          @click.stop.prevent="connector.id ? onSimulator($event) : undefined"
          :disabled="!connector.id"
          :title="`${$t('open')} ${$t('simulator')}`"
        >
          <b>
            <i class="glyphicon glyphicon-transfer"></i>
            {{ $t("simulator") }}
          </b>
        </span>
        <span
          v-if="!connector.base_model"
          class="btn btn-sm btn-white"
          @click.stop.prevent="connector.id ? onEventViewer() : undefined"
          :disabled="!connector.id"
          :title="`${$t('open')} ${$t('synoptic.panels.event_viewer')}`"
        >
          <b>
            <i class="fa fa-bug"></i>
            <span class="hidden-md hidden-lg">
              {{ $t("events") }}
            </span>
            <span class="hidden-xs hidden-sm">
              {{ $t("synoptic.panels.event_viewer") }}
            </span>
          </b>
        </span>
      </div>
    </div>
    <CustomActionManager v-bind:connector="connector" />
    <Modal
      iconClass="fa fa-gears"
      :open="showConfig"
      :title="`MQTT - ${$tc('configuration', 2)}`"
      :footer="false"
      :fitContent="false"
      :backdrop="busy ? 'static' : 'true'"
      :component_busy="busy"
      @update:open="showConfig = $event ? true : false"
      class="text-left"
    >
      <template #content>
        <MqttSettingsViewer
          :protocol="protocol"
          :connector="connector"
          v-if="showConfig"
        />
      </template>
    </Modal>
  </section>
</template>

<script>
import CustomActionManager from "@/components/custom-action-manager.vue";
import RealTimeEventViewer from "@/components/realtime-event-viewer.vue";
import MqttSettingsViewer from "@/components/registration/mqtt-settings-viewer.vue";
import Modal from "@/components/widgets/modal.vue";
export default {
  name: "MqttConnectorTools",
  components: {Modal, CustomActionManager, MqttSettingsViewer},
  props: {
    connector: {
      type: Object,
      required: true
    },
    protocol: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      showConfig: false,
      busy: false
    };
  },
  methods: {
    onEventViewer() {
      this.$root.$emit("controlSidebar:setContent", RealTimeEventViewer, {
        toggle: true,
        showToggle: false,
        clickAway: {
          allowed: [".inner-panel", ".tab-content", ".box"]
        }
      });
      this.$nextTick(() => {
        this.$root.$emit("setConnector", this.connector);
      });
    },
    onSimulator($event) {
      const prop_xy = [0.6, 0.6];
      let action = {
        type: $event.ctrlKey ? "page:tab" : "page:window",
        title: "page_window",
        options: {
          url: {
            required: true,
            placeholder: "URL",
            label: "url",
            value: "/static/common/pages/mqtt-sim.html",
            index: 0
          },
          auth: {
            required: true,
            label: "authenticated",
            placeholder: "authenticated",
            value: true,
            index: 1
          },
          params: $event.shiftKey
            ? {}
            : {
                left: parseInt(
                  (window.outerWidth - window.outerWidth * prop_xy[0]) / 2
                ),
                top: parseInt(
                  (window.outerHeight - window.outerHeight * prop_xy[1]) / 2
                ),
                width: parseInt(window.outerWidth * prop_xy[0]),
                height: parseInt(window.outerHeight * prop_xy[1])
              }
        }
      };
      this.$root.$emit("custom_action", {
        source: null,
        event: "click",
        connector: this.connector,
        action: action
      });
    }
  }
};
</script>

<style scoped>
.btn-container {
  display: flex;
  justify-content: center;
  gap: calc(4%);
  padding: 2px 0;
  background-color: transparent;
  border-color: transparent;
}

.btn-info-hi {
  color: #333;
  font-weight: 700;
  background-color: #9ed2d9;
  border-color: #93bcc1;
}

.btn-white {
  background-color: #fefefe;
  color: #333;
  border-color: #ccc;
}
</style>
